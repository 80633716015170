import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProgramService } from 'src/app/shared/services/program.service';
import { AddTermsComponent } from './add-terms/add-terms.component';
import { DeliveryContractComponent } from './delivery-contract/delivery-contract.component';
import { DeliveryPartnerComponent } from './delivery-partner/delivery-partner.component';
import { IndexComponent } from './index/index.component';

const routes: Routes = [
  {
    path: ':id',
    component: IndexComponent, data: { breadcrumb: (data: any) => `${data?.opportunity?.opportunity_name}` },
    resolve: { opportunity: ProgramService },
    children: [
      {
        path: 'delivery-approval',//data: { breadcrumb: 'Deal Structuring' } ,
        component: DeliveryPartnerComponent,
      },
      {
        path: 'award-deal',//data: { breadcrumb: 'Deal Structuring' } ,
        component: DeliveryPartnerComponent,
      },
      {
        path: 'delivery-contract',//data: { breadcrumb: 'Deal Structuring' } ,
        component: DeliveryContractComponent,
      },
      {
        path: 'add-terms',//data: { breadcrumb: 'Deal Structuring' } ,
        component: AddTermsComponent,
      }



    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ToeRoutingModule { }
