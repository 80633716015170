<div class="rightpanel">
    <div class="container-fluid centerpanel">
        <div class="custom-height" id="custom-height">
            <div class="row">
                <div class="col-md-12">
                    <app-bread-crumb></app-bread-crumb>
                    <!--  <p class="text-muted" style="font-size: 12px;">Programs / FlyDelta /
                        FlyDelta Website Development
                    </p> -->
                    <div class="row mb-4">
                        <div class="col-md-10">
                            <div class="pt-3">
                                <h2 class="screen-name">{{staticText?.proposal.side_bar.dp_contracts}}</h2>
                            </div>
                        </div>
                    </div>
                    <div class="scroll">
                        <div class="mb-2 position-relative"
                            *ngFor="let i of list | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount } ; let e = index">
                            <div class="row grid m-0 pb-1 cursor-pointer justify-content-between">
                                <div class="col-md-4">
                                    <div class="pl-2 d-flex justify-content-between">
                                        <div class="d-flex">
                                            <div class="mr-3 ">
                                                <span class="name-1" [ngStyle]="{'backgroundColor': e|getRandomColor}"
                                                    ><!--[ngClass]="{'titlecase-1':  i.vendor_details.name.includes('H'), 'titlecase-7': i.vendor_details.name.includes('Y'), 'titlecase-3': i.vendor_details.name.includes('D') }"-->
                                                    {{getName(i.vendor_details.name)}}</span>
                                            </div>
                                            <div>
                                                <div class="d-flex score-count">
                                                    <p class="company-name">
                                                        {{i.vendor_details.name | titlecase}}</p>
                                                </div>
                                                <br>
                                                <div class="d-flex">
                                                    <small class="score-value m-0 text-dark team p-0">
                                                        {{i.vendor_details.team}}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-md-4 p-0">
                                    <span class="name position-relative"  [ngStyle]="{'backgroundColor': e|getRandomColor}"
                                        >{{getShortName(i.vendor_details.primary_contact_details?.username)}}</span><!--[ngClass]="{'titlecase-6':  i.vendor_details.primary_contact_details.username.includes('H'), 'titlecase-7': i.vendor_details.primary_contact_details.username.includes('Y'), 'titlecase-3': i.vendor_details.primary_contact_details.username.includes('D'), 'titlecase-5': i.vendor_details.primary_contact_details.username.includes('A') }"-->
                                    <p class="td-align pl-2 pr-2 mt-2 w-75 text-truncate"
                                        matTooltip="{{i.vendor_details.primary_contact_details?.username}}"  matTooltipClass="example-tooltip">
                                        {{i.vendor_details.primary_contact_details?.username}}</p>
                                </div>
                                <div class="col-md-3 status-col p-0 pt-2">
                                    <div class="d-flex align-items-center" *ngIf="i.toe_status == 'Open'">
                                        <i class="far fa-check-circle my-1 score-model open"></i>
                                        <p class="m-0 score-value">
                                            {{i.toe_status}}</p>
                                    </div>
                                    <div class="d-flex align-items-center" *ngIf="i.toe_status == 'In progress'">
                                        <i class="far fa-clock my-1 score-model progress-status status-inprogress"></i>
                                        <p class="m-0">
                                            {{i.toe_status}}</p>
                                    </div>
                                    <div class="d-flex align-items-center" *ngIf="i.toe_status == 'Closed'">
                                        <i class="fad fa-pennant my-1 score-model closed "></i>
                                        <p class="m-0 status-closed">
                                            {{i.toe_status}}</p>
                                    </div>
                                </div>
                                <div class="col-md-auto pl-2 pt-2">
                                    <div class="d-flex align-items-center"
                                        *ngxPermissionsOnly="['proposal.change_vendorcontract']">
                                        <mat-select class=" pt-1 dropdown"
                                            (selectionChange)="selectToeStatus($event , i)" placeholder="SELECT"
                                            [(ngModel)]="i.updatedStatus"
                                            [ngClass]="{'approved': i.status.label == 'Approved' , 'rejected': i.status.label == 'Rejected'}">
                                            <mat-option *ngFor='let s of statusList' [value]="s">
                                                <span>{{s.action_complete_label | titlecase}}</span>
                                            </mat-option>
                                        </mat-select>
                                        <div class="px-2" (click)="editToe(i)"
                                            [ngClass]="{'rejected-opportunity': i.status.label == 'Rejected'}">
                                            <i class="fas fa-arrow-right navigate button"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="last-modified" matTooltip="Last Updated by: {{i.last_updated_by.username}}"  matTooltipClass="example-tooltip">
                                <p>Last Updated {{i.last_updated_on | dateAgo}}</p>
                            </div>
                        </div>

                        <div class="row" *ngIf="list?.length == 0">
                            <div class="col-md-5 mx-auto">
                                <img src="./assets/images/no-record.png" class="noFound" height="100%"
                                    width="100%">
                            </div>
                            <div class="col-md-8 mx-auto">
                                <p class="text-center fw-bold text-muted"> {{staticText.common.no_records_found}}
                                </p>
                            </div>
                        </div>


                    </div>
                    

                    <div class="row" *ngIf="list?.length != 0" style="min-height: 2rem;">
                        <div class="row">
                            <div class=" d-flex justify-content-end align-items-center ">
                                <div classs="d-flex" style="padding: 0.8rem 2.2rem  0rem 0.1rem;">
                                    <pagination-controls nextLabel="" previousLabel=""
                                        (pageChange)="onChangeTable($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>