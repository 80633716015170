import { formatDate } from '@angular/common';
import { ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { map, startWith } from 'rxjs/operators';
import { DeletePopupComponent } from 'src/app/shared/components/delete-popup/delete-popup.component';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import { LodashService } from 'src/app/shared/services/lodash.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { TermsService } from 'src/app/shared/services/terms.service';
import { Chart } from 'angular-highcharts';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-add-terms',
  templateUrl: './add-terms.component.html',
  styleUrls: ['./add-terms.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class AddTermsComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @ViewChild('updateRisk') updateRiskModal: any;
  activeTab='setup';
  historyModal:any;
  treeMapChart1: any;
  opportunityData:any;
  termHeatMapData: any;
  selectedTerm='';

  termsToeForm!: FormGroup

  searchText!: string;
  ToeList: any[] = [];
  masterTOEList: any[] = [];
  slaveReCommendedTOEList: any[] = [];
  slaveCustomTOEList: any[] = [];
  patchTOEList: any[] = [];
  formArray: any[] = [];

  showCustomToeForm: boolean = false;
  opportunityId: any;
  categoryExist: boolean = false;
  subCategoryExist: any;
  constantSubCategory: any[] = [];
  categoryPayload: any;
  submitted: boolean = false;
  filterOptionTitle: any;
  categoryList: any[] = [];
  allTerms:any=[];

  constructor(
    private ProgramService: ProgramService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private termsService: TermsService,
    private lodashService: LodashService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _formValidator:FormValidatorService
  ) { }

  ngOnInit(): void {
    this.route?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id)
    });
    this.createForms()
    this.getOpportunityById();

    this.filterOptionTitle = this.termsToeForm.get('category')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.categoryTitle),
        map((name: any) => name ? this._filterArray(name, this.categoryList, 'categoryTitle') : this.categoryList.slice())
      );
  }

  createForms() {
    this.termsToeForm = this.formBuilder.group({
      category: ['', [Validators.required,this._formValidator.autocompleteObjectValidator]],
      subCategory: [''],
      measurement: ['', Validators.required],
    });
  }


  private _filterArray(value: any, arr: any = [], key: any): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((tag: any) => tag?.[key]?.toLowerCase().includes(filterValue));
  }


  get contractTermFormControls() {
    return this.termsToeForm.controls;
  }


  getToeList() {
    this.ProgramService.getOpportunityFormData('terms_of_engagement').subscribe((res: any) => {
      this.patchTOEList = this.lodashService.Clonedata(res?.records?.find((findTerms: any) => findTerms?.field_name === 'opportunity_contract_terms')?.field_values);
      this.ToeList = res?.records?.find((findTerms: any) => findTerms?.field_name === 'opportunity_contract_terms')?.field_values;
      this.filterDuplicates(this.ToeList, this.masterTOEList);
      this.slaveReCommendedTOEList = this.lodashService.Clonedata(this.ToeList).filter((filterRecommended: any) => filterRecommended?.is_custom == false);
      this.slaveCustomTOEList = this.lodashService.Clonedata(this.ToeList).filter((filterRecommended: any) => filterRecommended?.is_custom == true);
      this.ToeList.forEach((List: any) => {
        this.categoryList.push({ categoryTitle: List.category_display_name });
      })
    })
  }

  filterDuplicates(listArray: any[], patchArray: any[]) {
    if (listArray && patchArray) {
      patchArray?.forEach((patchCategoryArray: any) => {
        patchCategoryArray?.term_sub_category?.forEach((patchSubCategoryArray: any) => {
          patchSubCategoryArray?.term_measurement_criteria?.forEach((patchMeasurementArray: any) => {
            let findCategory = listArray.find((findCategory: any) => findCategory?.category_uuid == patchCategoryArray?.category_uuid);
            let categoryIndex = listArray.findIndex((categoryIndx: any) => categoryIndx?.category_uuid == patchCategoryArray?.category_uuid);
            if (findCategory) {
              let subParentIndex = findCategory?.term_sub_category?.findIndex((subIndx: any) => subIndx?.sub_category_uuid == patchSubCategoryArray?.sub_category_uuid);
              findCategory?.term_sub_category?.forEach((findSubCategory: any) => {
                if (findSubCategory.sub_category_uuid == patchSubCategoryArray?.sub_category_uuid) {
                  let measurementIndex = findSubCategory?.term_measurement_criteria?.findIndex((measurementIndx: any) => measurementIndx.measurement_criteria_uuid == patchMeasurementArray.measurement_criteria_uuid);
                  findSubCategory?.term_measurement_criteria?.splice(measurementIndex, 1);
                  if (findSubCategory?.term_measurement_criteria?.length == 0 && findCategory?.term_sub_category?.length == 0) {
                    listArray?.splice(categoryIndex, 1);
                  }
                  if (findSubCategory?.term_measurement_criteria?.length == 0) {
                    findCategory?.term_sub_category.splice(subParentIndex, 1);
                    if (findCategory?.term_sub_category?.length == 0) {
                      listArray?.splice(categoryIndex, 1);
                    }
                    let parentIndex = findCategory?.term_sub_category?.findIndex((subIndx: any) => subIndx?.sub_category_uuid == patchSubCategoryArray?.sub_category_uuid);
                    if (parentIndex !== -1) {
                      findCategory?.term_sub_category.splice(subParentIndex, 1);
                    }
                  }
                } else {
                  if (findSubCategory?.term_measurement_criteria?.length == 0 && findCategory?.term_sub_category?.length == 0) {
                    listArray?.splice(categoryIndex, 1);
                  }
                }
              });
            }

          })
        })
      })
    }
    return listArray;
  }

  getOpportunityById() {
    this.ProgramService.getOpportunityById(this.opportunityId).subscribe((res:any)=>{  
      this.valuePatch(res);
    })
  }

  valuePatch(data: any) {
    if (data?.contract_terms) {
      let termArray: any[] = []
      data?.contract_terms?.forEach((element: any) => {
        termArray.push(element?.term_category)
        element?.term_category?.term_sub_category?.forEach((Measurement: any) => {
          Measurement?.term_measurement_criteria?.forEach((measurement: any) => {
            this.createItem(measurement);
          });
          this.allTerms = this.allTerms.concat(Measurement.term_measurement_criteria);
        });
      });
      this.masterTOEList = termArray;
      this.sortRecordsList(this.masterTOEList, this.masterTOEList?.length + 1)
      this.getToeList();
    }

  }


  createItem(data: any): FormGroup {
    return this.formBuilder.group({
      milestone: [data?.milestone || '', Validators.required],
      deadline: [data?.deadline || '', Validators.required],
      notes: [data?.notes || '', Validators.required],
      criteria_uuid: [data?.measurement_criteria_uuid || '']
    });
  }


  removeRightPaneRecommended(data: any) {
    this.addMeasurementCenterPane(data);
    let categoryIndex = this.slaveReCommendedTOEList.findIndex((categoryIndex: any) => categoryIndex.category_uuid == data.category_uuid);
    this.slaveReCommendedTOEList.filter((category: any) => {
      if (category?.category_uuid == data?.category_uuid) {

        category.term_sub_category.forEach((subCategory: any, subCategoryindex: number) => {
          if (subCategory?.term_measurement_criteria?.length != 0 || null) {
            if (subCategory?.sub_category_uuid == data?.sub_category_uuid) {
              for (let j = 0; j < subCategory.term_measurement_criteria.length; j++) {

                let childKeyResultIndex = subCategory?.term_measurement_criteria.findIndex((child: any) => child.measurement_criteria_uuid == data.measurement_criteria_uuid)
                subCategory?.term_measurement_criteria.splice(childKeyResultIndex, 1);
                if (subCategory?.term_measurement_criteria?.length == 0 && category?.term_sub_category?.length == 0) {
                  this.slaveReCommendedTOEList.splice(categoryIndex, 1);
                }
                if (subCategory?.term_measurement_criteria?.length == 0) {
                  category?.term_sub_category.splice(subCategoryindex, 1);
                  if (category?.term_sub_category?.length == 0) {
                    this.slaveReCommendedTOEList.splice(categoryIndex, 1);

                  }
                  let parentIndex = category?.term_sub_category.findIndex((sliceParentIndex: any) => sliceParentIndex?.sub_category_uuid == data.sub_category_uuid);
                  if (parentIndex !== -1) {
                    category?.term_sub_category.splice(parentIndex, 1);
                  }
                }
                break;
              }
            } else {
              if (subCategory?.term_measurement_criteria?.length == 0 && category?.term_sub_category?.length == 0) {
                this.slaveReCommendedTOEList.splice(categoryIndex, 1);
              }
            }
          }
        });
      }
    })

  }

  removeRightPaneCustom(data: any) {
    this.addMeasurementCenterPane(data);
    let categoryIndex = this.slaveCustomTOEList.findIndex((categoryIndex: any) => categoryIndex.category_uuid == data.category_uuid);
    this.slaveCustomTOEList.filter((category: any) => {
      if (category?.category_uuid == data?.category_uuid) {

        category.term_sub_category.forEach((subCategory: any, subCategoryindex: number) => {
          if (subCategory?.term_measurement_criteria?.length != 0 || null) {
            if (subCategory?.sub_category_uuid == data?.sub_category_uuid) {
              for (let j = 0; j < subCategory.term_measurement_criteria.length; j++) {

                let childKeyResultIndex = subCategory?.term_measurement_criteria.findIndex((child: any) => child.measurement_criteria_uuid == data.measurement_criteria_uuid)
                subCategory?.term_measurement_criteria.splice(childKeyResultIndex, 1);
                if (subCategory?.term_measurement_criteria?.length == 0 && category?.term_sub_category?.length == 0) {
                  this.slaveCustomTOEList.splice(categoryIndex, 1);
                }
                if (subCategory?.term_measurement_criteria?.length == 0) {
                  category?.term_sub_category.splice(subCategoryindex, 1);
                  if (category?.term_sub_category?.length == 0) {
                    this.slaveCustomTOEList.splice(categoryIndex, 1);

                  }
                  let parentIndex = category?.term_sub_category.findIndex((sliceParentIndex: any) => sliceParentIndex?.sub_category_uuid == data.sub_category_uuid);
                  if (parentIndex !== -1) {
                    category?.term_sub_category.splice(parentIndex, 1);
                  }
                }
                break;
              }
            } else {
              if (subCategory?.term_measurement_criteria?.length == 0 && category?.term_sub_category?.length == 0) {
                this.slaveCustomTOEList.splice(categoryIndex, 1);
              }
            }
          }
        });
      }
    })
  }

  addMeasurementCenterPane(data: any) {
    let found = this.allTerms.find((o:any)=>o.measurement_criteria_uuid == data.measurement_criteria_uuid);
    if(!found)
      this.allTerms.push(data);
    // below code we will find category for patchToeData it contains all category data
    let keyData1 = { ...this.patchTOEList.find((i: any) => i.category_uuid == data.category_uuid) };
    let subKey = this.lodashService.Clonedata(keyData1);
    let keyData = this.lodashService.Clonedata(keyData1);
    let results = keyData.term_sub_category.filter((i: any) => i.sub_category_uuid == data.sub_category_uuid);
    const isExist = this.masterTOEList.some((existStatus: any) => existStatus.category_uuid == keyData.category_uuid);
    keyData.term_sub_category = results;
    let measurement = keyData.term_sub_category[0].term_measurement_criteria.filter((el: any) => {
      if (el.measurement_criteria_uuid == data.measurement_criteria_uuid) {
        return el;
      }
    });
    if (!isExist) {
      keyData.term_sub_category[0].term_measurement_criteria = measurement;
      this.masterTOEList.push(keyData);
    } else {
      let index = this.masterTOEList.findIndex((i: any) => i.category_uuid == data.category_uuid);
      const subValue = this.masterTOEList[index].term_sub_category.find((z: any) => z.sub_category_uuid == data.sub_category_uuid);
      if (subValue) {
        subValue.term_measurement_criteria.push(...measurement);
      } else {
        const subCopy = subKey.term_sub_category.find((x: any) => x.sub_category_uuid == data.sub_category_uuid);
        subCopy.term_measurement_criteria = measurement;
        this.masterTOEList[index].term_sub_category.push(subCopy);
      }
    }
    for (let item in this.masterTOEList) {
      this.masterTOEList[item]['term_sub_category'] = this.sortRecordsList(this.masterTOEList[item]['term_sub_category'], this.masterTOEList[item]['term_sub_category']?.length + 1);
      for (let child in this.masterTOEList[item]['term_sub_category']) {
        this.masterTOEList[item]['term_sub_category'][child]['term_measurement_criteria'] = this.sortRecordsList(this.masterTOEList[item]['term_sub_category'][child]['term_measurement_criteria'], this.masterTOEList[item]['term_sub_category'][child]['term_measurement_criteria']?.length + 1);
      }
    }
  }


  removeTOEMeasurement(item: any) {
    this.allTerms = this.allTerms.filter((o:any)=>o.measurement_criteria_uuid != item.measurement_criteria_uuid);
    this.masterTOEList?.filter((category: any, categoryIndex: number) => {
      if (category?.category_uuid == item.category_uuid) {
        if (category?.term_sub_category?.length != 0) {
          category?.term_sub_category.forEach((subCategory: any, subCategoryIndex: number) => {
            let measurementCriteriaIndex = subCategory?.term_measurement_criteria?.findIndex((measuremen: any) => measuremen?.measurement_criteria_uuid == item?.measurement_criteria_uuid)
            if (measurementCriteriaIndex !== -1) {
              subCategory?.term_measurement_criteria.splice(measurementCriteriaIndex, 1);
            }
            if (subCategory?.term_measurement_criteria?.length == 0) {
              category?.term_sub_category.splice(subCategoryIndex, 1);
            }
            if (category?.term_sub_category?.length == 0) {
              this.masterTOEList.splice(categoryIndex, 1);
            }
          })
        } else {
          this.masterTOEList.splice(categoryIndex, 1);
        }
      }
    })
    let formIndex = this.formArray.findIndex((formIndx: any) => formIndx.uuid == item.measurement_criteria_uuid);
    this.formArray.splice(formIndex, 1);
    if (!item.goal_is_custom) {
      this.patchRecommendedToeCategory(item);
    } else {
      this.patchCustomToeCategory(item);
    }
  }

  patchRecommendedToeCategory(item: any) {
    if (this.slaveReCommendedTOEList && this.slaveReCommendedTOEList?.length !== 0) {
      let categoryIndex = this.slaveReCommendedTOEList?.findIndex((categoryIndx: any) => categoryIndx?.category_uuid == item.category_uuid);
      if (categoryIndex !== -1) {
        for (var categorydata of this.slaveReCommendedTOEList) {
          if (categorydata) {
            if (categorydata?.category_uuid == item.category_uuid) {
              let parenKeyResultIndex = categorydata.term_sub_category.findIndex((parentKeyResultsIndex: any) => parentKeyResultsIndex.sub_category_uuid == item.sub_category_uuid);
              if (parenKeyResultIndex !== -1) {
                categorydata.term_sub_category[parenKeyResultIndex].term_measurement_criteria.push(item);
                break;
              } else {
                if (this.patchTOEList.length !== 0) {
                  for (var patchCategory of this.patchTOEList) {
                    if (patchCategory) {
                      if (patchCategory?.category_uuid == item.category_uuid) {
                        let patchParentIndex = patchCategory.term_sub_category.findIndex((patchParentKeyResults: any) => patchParentKeyResults.sub_category_uuid == item.sub_category_uuid);
                        if (patchParentIndex !== -1) {
                          for (var patchParent of patchCategory.term_sub_category) {
                            let patchParentclone = this.lodashService.Clonedata(patchParent);
                            if (patchParent.sub_category_uuid == item.sub_category_uuid) {
                              patchParentclone = patchParent;
                              patchParentclone.term_measurement_criteria = [];
                              for (var patchLocalArray of this.slaveReCommendedTOEList) {
                                if (patchLocalArray.category_uuid == item.category_uuid) {
                                  patchLocalArray.term_sub_category.push(patchParentclone);
                                  patchParentclone.term_measurement_criteria.push(item);
                                  break;
                                }
                              }
                              break;
                            }
                          }
                        }
                        break;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }

      } else {
        if (this.patchTOEList.length !== 0) {
          for (var patchNewCategory of this.patchTOEList) {
            if (patchNewCategory) {
              if (patchNewCategory.category_uuid == item.category_uuid) {
                let patchNewSubCategoryIndex = patchNewCategory.term_sub_category.findIndex((patchNewCategoryParentKeyResults: any) => patchNewCategoryParentKeyResults.sub_category_uuid == item.sub_category_uuid);
                if (patchNewSubCategoryIndex !== -1) {
                  let patchNewCategoryclone = this.lodashService.Clonedata(patchNewCategory);
                  let patchNewCategoryParent = patchNewCategory.term_sub_category.filter((patchNewCategoryparentdata: any) => patchNewCategoryparentdata.sub_category_uuid == item.sub_category_uuid);
                  if (patchNewCategory.category_uuid == item.category_uuid) {
                    patchNewCategoryclone.term_sub_category = patchNewCategoryParent;
                    patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria = []
                    patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria.push(item);
                    let patchNewChildIndex = patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria.findIndex((patchNewChildIndex: any) => patchNewChildIndex.measurement_criteria_uuid == item.measurement_criteria_uuid);
                    patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria = this.lodashService.sortUniqueBy(patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria, patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria[patchNewChildIndex].measurement_criteria_uuid)
                    this.slaveReCommendedTOEList.push(patchNewCategoryclone);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  patchCustomToeCategory(item: any) {
    if (this.slaveCustomTOEList && this.slaveCustomTOEList?.length !== 0) {
      let categoryIndex = this.slaveCustomTOEList?.findIndex((categoryIndx: any) => categoryIndx?.category_uuid == item.category_uuid);
      if (categoryIndex !== -1) {
        for (var categorydata of this.slaveCustomTOEList) {
          if (categorydata) {
            if (categorydata?.category_uuid == item.category_uuid) {
              let parenKeyResultIndex = categorydata.term_sub_category.findIndex((parentKeyResultsIndex: any) => parentKeyResultsIndex.sub_category_uuid == item.sub_category_uuid);
              if (parenKeyResultIndex !== -1) {
                categorydata.term_sub_category[parenKeyResultIndex].term_measurement_criteria.push(item);
                // categorydata.term_sub_category[parenKeyResultIndex].term_measurement_criteria = this.lodashService.orderBy(categorydata.term_sub_category[parenKeyResultIndex].term_measurement_criteria, "key_result_title", "asc");
                break;
              } else {
                if (this.patchTOEList.length !== 0) {
                  for (var patchCategory of this.patchTOEList) {
                    if (patchCategory) {
                      if (patchCategory?.category_uuid == item.category_uuid) {
                        let patchParentIndex = patchCategory.term_sub_category.findIndex((patchParentKeyResults: any) => patchParentKeyResults.sub_category_uuid == item.sub_category_uuid);
                        if (patchParentIndex !== -1) {
                          for (var patchParent of patchCategory.term_sub_category) {
                            let patchParentclone = this.lodashService.Clonedata(patchParent);
                            if (patchParent.sub_category_uuid == item.sub_category_uuid) {
                              patchParentclone = patchParent;
                              patchParentclone.term_measurement_criteria = [];
                              for (var patchLocalArray of this.slaveCustomTOEList) {
                                if (patchLocalArray.category_uuid == item.category_uuid) {
                                  patchLocalArray.term_sub_category.push(patchParentclone);
                                  patchParentclone.term_measurement_criteria.push(item);
                                  // patchLocalArray.term_sub_category = this.lodashService.orderBy(patchLocalArray.term_sub_category, "key_result_title", "asc");
                                  break;
                                }
                              }
                              break;
                            }
                          }
                        }
                        break;
                      }
                    }
                  }
                }
                break;
              }
            }
          }
        }

      } else {
        if (this.patchTOEList.length !== 0) {
          for (var patchNewCategory of this.patchTOEList) {
            if (patchNewCategory) {
              if (patchNewCategory.category_uuid == item.category_uuid) {
                let patchNewSubCategoryIndex = patchNewCategory.term_sub_category.findIndex((patchNewCategoryParentKeyResults: any) => patchNewCategoryParentKeyResults.sub_category_uuid == item.sub_category_uuid);
                if (patchNewSubCategoryIndex !== -1) {
                  let patchNewCategoryclone = this.lodashService.Clonedata(patchNewCategory);
                  let patchNewCategoryParent = patchNewCategory.term_sub_category.filter((patchNewCategoryparentdata: any) => patchNewCategoryparentdata.sub_category_uuid == item.sub_category_uuid);
                  if (patchNewCategory.category_uuid == item.category_uuid) {
                    patchNewCategoryclone.term_sub_category = patchNewCategoryParent;
                    patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex]['term_measurement_criteria'] = []
                    patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex]['term_measurement_criteria'].push(item);
                    let patchNewChildIndex = patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex]['term_measurement_criteria'].findIndex((patchNewChildIndex: any) => patchNewChildIndex.measurement_criteria_uuid == item.measurement_criteria_uuid);
                    patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex]['term_measurement_criteria'] = this.lodashService.sortUniqueBy(patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria, patchNewCategoryclone.term_sub_category[patchNewSubCategoryIndex].term_measurement_criteria[patchNewChildIndex].measurement_criteria_uuid)
                    this.slaveCustomTOEList.push(patchNewCategoryclone);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  addCustomTerms() {
    this.ToeList.forEach((category: any) => {
      if (category?.term_sub_category?.length > 0) {
        category?.term_sub_category.forEach((subCategory: any) => {
          this.constantSubCategory.push(subCategory)
        });
      }
    });
    this.submitted = true;
    if (this.termsToeForm.invalid) {
      this.spinner.hide();
      return;
    }
    if (typeof (this.termsToeForm.value.category) == 'object') {
      this.termsToeForm.value.category = this.termsToeForm.value.category.categoryTitle
    } if (typeof (this.termsToeForm.value.category) == 'string') {
      this.termsToeForm.value.category = this.termsToeForm.value.category
    }
    this.categoryExist = false;
    const catObj = this.ToeList.find((i: any) => i.category_display_name == this.termsToeForm.value.category);
    const subCatObj = this.constantSubCategory.find((i: any) => i.sub_category_display_name == this.termsToeForm.value.subCategory);
    if (catObj) {
      this.categoryExist = this.ToeList.some((i: any) => i.category_display_name == this.termsToeForm.value.category);
    }
    if (subCatObj) {
      this.subCategoryExist = this.constantSubCategory.some((i: any) => i.sub_category_display_name == this.termsToeForm.value.subCategory);
    } else {
      this.subCategoryExist = false;
    }

    if (this.categoryExist && !this.subCategoryExist) {
      this.categoryPayload = {
        term_category: {
          uuid: catObj.category_uuid,
        },
        term_sub_category: {
          name: this.termsToeForm.value.subCategory ? this.termsToeForm.value.subCategory : 'Not Specified',
          display_name: this.termsToeForm.value.subCategory ? this.termsToeForm.value.subCategory : 'Not Specified',
          is_custom: true
        },
        term_measurement_criteria: {
          name: this.termsToeForm.value.measurement,
          display_name: this.termsToeForm.value.measurement,
          is_custom: true
        }
      }
    } else if (this.categoryExist == true && this.subCategoryExist == true) {
      this.categoryPayload = {
        term_category: {
          uuid: catObj.category_uuid,
        },
        term_sub_category: {
          uuid: subCatObj.sub_category_uuid
        },
        term_measurement_criteria: {
          name: this.termsToeForm.value.measurement,
          display_name: this.termsToeForm.value.measurement,
          is_custom: true
        }
      }
    } else {
      this.categoryPayload = {
        term_category: {
          name: this.termsToeForm.value.category,
          display_name: this.termsToeForm.value.category,
          is_custom: true
        },
        term_sub_category: {
          name: this.termsToeForm.value.subCategory ? this.termsToeForm.value.subCategory : 'Not Specified',
          display_name: this.termsToeForm.value.subCategory ? this.termsToeForm.value.subCategory : 'Not Specified',
          is_custom: true
        },
        term_measurement_criteria: {
          name: this.termsToeForm.value.measurement,
          display_name: this.termsToeForm.value.measurement,
          is_custom: true
        }
      }
    }
    this.termsService.createCustomTerms(this.categoryPayload).subscribe((res: any) => {
      if (res.status == 201) {
        this.toastrService.success('Custom Terms created successfully');
        this.getToeList();
        this.showCustomToeForm = false;
        this.spinner.hide();
        return;
      } else if (res.status == 200) {
        this.toastrService.error('Custom Term already exist in our records');
        this.spinner.hide();
      }
    }, error => {
      this.toastrService.error('Could not get data');
      this.spinner.hide();
      throw error;
    });
  }

  createFormDataPayload(data?: any) {
    let formData: any[] = []
    if (data&& data.length>0) {
      for (var formdata of data) {
        if (formdata?.measurement_criteria_uuid && formdata?.milestone
          && formdata?.deadline && formdata?.notes) {
          var selecterMeasurement: any = {
            uuid: formdata?.measurement_criteria_uuid,
            milestone: formdata?.milestone,
            deadline: formatDate(formdata?.deadline, 'yyyy-MM-dd', 'en-US'),
            notes: formdata?.notes
          }
          formData.push(selecterMeasurement);
          this.lodashService.sortUniqueBy(formData, data.measurement_criteria_uuid);
          this.formArray = this.formArray.filter((o:any)=>o.uuid !=formdata?.measurement_criteria_uuid )
          this.formArray.push(formData);
        }
      }
    }
  }

  saveTOE() {
    const payload: any = {
      terms_data: []
    };
    
    (this.masterTOEList || []).forEach((t: any) => {
      const uuid1: any = {
        uuid: t.category_uuid,
        term_subcategory: []
      };
      (t.term_sub_category || []).forEach((s: any) => {
        const uuid2: any = {
          uuid: s.sub_category_uuid,
          term_measurement_criteria: []
        };
        
        (s.term_measurement_criteria || []).forEach((m: any) => {
          let foundItem= this.formArray.find((o:any)=> o.uuid === m.measurement_criteria_uuid)
          if(foundItem){
            uuid2.term_measurement_criteria.push(foundItem);
          }else{
            const uuid3: any = {              
              uuid: m?.measurement_criteria_uuid,
              milestone: m?.milestone,
              deadline: m?.deadline ?formatDate(m?.deadline, 'yyyy-MM-dd', 'en-US'): m?.deadline,
              notes: m?.notes
            };
            uuid2.term_measurement_criteria.push(uuid3);
          }
        });
        uuid1.term_subcategory.push(uuid2);
      });
      payload.terms_data.push(uuid1);
    });
    this.spinner.show();
    this.ProgramService.updateOpportunity(this.opportunityId, payload).subscribe((res: any) => {
      this.spinner.hide();      
      if (res) {
        this.getToeList();
        this.toastrService.success('Terms Created Successfully');
      }
    }, error => {
      this.spinner.hide();
      this.toastrService.error("Please fill mandatory fields")
      throw error;
    })

  }

  sortRecordsList(arr: any = [], key: any) {
    arr.sort((a: any, b: any) => {
      if (a[key] === b[key]) {
        return 0;
      } else if (a[key] === null) {
        return 1;
      } else if (b[key] === null) {
        return -1;
      } else if (a[key] < b[key]) {
        return -1;
      } else {
        return 1;
      }
    });
    return arr;
  }

  deletePopUp(id: number) {
    let dialogRef = this.dialog.open(DeletePopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTermsById(id);
      }
    });
  }

  deleteTermsById(data: any) {
    this.spinner.show();
    this.termsService.deleteMeasurement(data.measurement_criteria_uuid, data.sub_category_uuid).subscribe((res: any) => {
      if (!res) {
        this.spinner.hide();
        if (data.sub_category_uuid) {
          this.deleteCustomSubcategory(data);
        }
      } else {
        this.toastrService.success('Deleted successfully');
        this.spinner.hide();
        this.getToeList();
      }

    }, (error: any) => {
      this.spinner.hide();
      this.toastrService.error('Could not delete custom Measurement');
      throw error;
    });
  }

  deleteCustomSubcategory(item: any) {
    this.spinner.show();
    this.termsService.deleteSubcategory(item.sub_category_uuid, item.category_uuid).subscribe((res: any) => {
      if (!res) {
        this.spinner.hide();
        if (item.category_uuid) {
          this.deleteCustomCategory(item);
        }
      } else {
        this.toastrService.success('Deleted successfully');
        this.spinner.hide();
        this.getToeList();
      }
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not delete sub category');
      throw error;
    });
  }
  deleteCustomCategory(item: any) {
    this.spinner.show();
    this.termsService.deleteCategory(item.category_uuid).subscribe((res: any) => {
      this.toastrService.success('Deleted successfully');
      this.spinner.hide();
      this.getToeList();
    }, error => {
      this.spinner.hide();
      this.toastrService.error('Could not delete category');
      throw error;
    });
  }
  displayFn(data: any) {
    return data && data.categoryTitle ? data.categoryTitle : '';
  }
  
  changeTab(tabName:string){
    this.activeTab = tabName;
  }
  
  setHeatMapChart() {
    let call = this;
    let chartOptions1: any = {
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{
        type: "treemap",
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true,
              useHTML: true,
              inside: true,
              style: {
                // fontSize: '1rem',
                // fontWeight: "normal",
                // textOutline: "0",
                // textOverflow: "none",
                // padding:'5px',
                // width:"20px",
              },
              // rotation: 0,
              // y: 2,
              // x: 30,
              // formatter:{
              //   indentation:"justify"
              // }
            }
          }
        ],
        events: {
          click(e: any) {
            call.showRiskModal()
          }
        },
        layoutAlgorithm: 'squarified',
        data: [...this.termHeatMapData]
      }]
    };
    chartOptions1.series[0].levels[0].dataLabels.formatter = function () {
      return "<p class='pl-1'>" + this.point.name + "</p><p class='pl-1 point-values'><span>" + this.point.value + "</span> " + (this.point.value > 1 ? ' Risks' : ' Risk') + "</p>"
    }
    this.treeMapChart1 = new Chart(chartOptions1);
    
  }
 
  getRandomcolors() {
    const colors: any[] = ["#FFA800", "#1A85D6", "#07B797", "#738199", "#B58848", "#706FD3", "#F3819A", "#00F000", "#52E6CB", "#232459", "#EB55D7", "#738199", "#B58848", "#706FD3", "#F3821A", "#00F210", "#72E8CB", "#235359", "#EB32D7"];
    let colorIndex = Math.floor(Math.random() * colors.length);
    return colors[colorIndex];
  }
  showRiskModal(){
    this.dialog.open(this.updateRiskModal,{panelClass:"mw-100"});
  }  
  openModal(modal:any){
    this.historyModal = this.dialog.open(modal,{panelClass:"mw-100"})
  }
  closeDialog() {
    this.dialog.closeAll();
  }

}
