import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToeRoutingModule } from './toe-routing.module';
import { DeliveryPartnerComponent } from './delivery-partner/delivery-partner.component';
import { IndexComponent } from './index/index.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AvatarModule } from 'ngx-avatar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DeliveryContractComponent } from './delivery-contract/delivery-contract.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PipeModule } from 'src/app/shared/pipes/pipe.module';
import { AddTermsComponent } from './add-terms/add-terms.component';
import { MaterialmodulesModule } from 'src/app/shared/materialmodules.module';
import { ToeUploadModule } from '../toe-upload/toe-upload.module';
import { MatRadioModule } from '@angular/material/radio';
import { ChartModule } from 'angular-highcharts';




@NgModule({
  declarations: [
    DeliveryPartnerComponent,
    IndexComponent,
    DeliveryContractComponent,
    AddTermsComponent,
    
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ToeRoutingModule,
    SharedModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MaterialmodulesModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    AvatarModule,
    MatTooltipModule,
    NgxPaginationModule,
    PipeModule.forRoot(),
    ToeUploadModule,
    MatRadioModule,
    ChartModule
    /* TimeAgoPipe */
  ],
  exports: []
})
export class ToeModule { }
