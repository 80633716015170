import { Input, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ToeListService } from 'src/app/shared/services/toe-list.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-delivery-contract',
  templateUrl: './delivery-contract.component.html',
  styleUrls: ['./delivery-contract.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class DeliveryContractComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  list: any;
  customStyle = {
    backgroundColor: "#f1f1fc",
    borderRadius: "50%",
    color: "#232459",
    cursor: "pointer",
    fontWeight: 'bolder',
  };
  statusList: any;
  selectedStatus: any;
  selectedVendor: any;
  itemsPerPage: number = 10;
  page: number = 1;
  totalCount: any;
  endVal: number = 10;
  startVal: number = 0;
  ColorRandom: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private permissionService: NgxPermissionsService,
    private toeService: ToeListService
  ) { }

  ngOnInit(): void {
    const perm = JSON.parse(localStorage.getItem('permission') || '{}');
    console.log('perm', perm);
    this.permissionService.loadPermissions(perm.permissions);
    this.route?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id)
      this.getContractStatus();
   });
  }

  getVendorToe() {
    this.spinner.show();
    this.toeService.getVendorContract(this.opportunityId).subscribe((res: any) => {
      this.spinner.hide();
      console.log('res', res);
      this.list = res.records;
      this.list = this.list.map((i: any) => {
        //i.last_updated_on = !!i.last_updated_on ? moment(i.last_updated_on).format('DD-MM-yyyy  HH:mm') : '';
        this.statusList.forEach((element: any) => {
          if (element.action_complete_label == i.status.action_complete_label) {
            i.updatedStatus = element;
          }
        });
        return i;
      }, (error: any) => {
        this.spinner.hide();
        throw error;
      })
    })
  }

  getContractStatus() {
    this.spinner.show();
    this.toeService.getContractStatus().subscribe((res: any) => {
      this.spinner.hide();
      this.getVendorToe();
      console.log('statusList', res)
      this.statusList = res;
    }, (error: any) => {
      throw error;
    })
  }

  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getVendorToe();
  }



  selectToeStatus(e: any, i: any) {
    this.selectedStatus = e.value.slug;
    this.selectedVendor = i.vendor;
    this.updateContractStatus();
  }

  updateContractStatus() {
    const payload = {
      opportunity: parseInt(this.opportunityId),
      vendor: this.selectedVendor,
      status: this.selectedStatus
    }
    this.toeService.updateContractStatus(payload).subscribe((res: any) => {
      if (res == true) {
        this.toastrService.success("Updated Succesfully")
        this.getVendorToe();
      }

    }, (error: any) => {
      throw error;
    })
  }

  editToe(i: any) {
    console.log('i', i);
    this.router.navigate([`toeUpload/${btoa(this.opportunityId)}/${i.vendor}/toe-files`]);
  }

  getShortName(fullName: any) {
    return fullName.split(' ').map((n: any) => n[0]).join('');
  }

  getName(name: any) {

    return name.split(' ').slice(0, 2).map((n: any) => n[0],).join('');

  }

}


/*  .split(' ')
      .slice(0, numChars)
      .map(n => n[0].toUpperCase())
      .join(''); */
