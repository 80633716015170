import { DatePipe } from '@angular/common';
import { ViewEncapsulation } from '@angular/core';
import { Component, OnInit,AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ToeListService } from 'src/app/shared/services/toe-list.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-delivery-partner',
  templateUrl: './delivery-partner.component.html',
  styleUrls: ['./delivery-partner.component.scss'],
  encapsulation:ViewEncapsulation.Emulated,
  providers:[DatePipe]
})
export class DeliveryPartnerComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  opportunityId: any;
  list: any;
  statusList: any;
  selectedStatus: any;
  selectedVendor: any;
  itemsPerPage: number = 10;
  page: number = 1;
  totalCount: any;
  endVal: number = 10;
  startVal: number = 0;
  ColorRandom: any;
  //activeTab:any = {};
  selectedFile:any={};
  sowForm:FormGroup|any;
  files:any=[];
  disableFields: boolean= false;
  opportunityStatus:any={};
  opportunity_data: any;
  breadcrumb: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private permissionService: NgxPermissionsService,
    private toeService: ToeListService,
    private fb:FormBuilder,
    private datePipe:DatePipe,
    private ps:ProgramService,
    private breadCrumService: BreadcrumbService,
    private messageService: MessageService
  ) { 
    this.sowForm = this.fb.group({
      sow:new FormArray([])
    })
   }

  ngOnInit(): void {
    this.messageService.setGnews('Opportunity Award/Deal')
    const perm = JSON.parse(localStorage.getItem('permission') || '{}');
    console.log('perm', perm);
    this.permissionService.loadPermissions(perm.permissions);
    this.route?.parent?.params.subscribe((res: any) => {
      this.opportunityId = atob(res.id);
      //this.getStatusList();      
      this.getVendorToe();
    }); 

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })

  }
  createForm(){
    return this.fb.group({
      "contractAwarded":[null,Validators.required],
      "projectID":[null, Validators.pattern("^[0-9]*$")],
      projectName:[""],
      "projectValue":[""],
      "beginDate":[""],
      "endDate":[""],
      "document":[null],
    })
  }
  radioChange(event: MatRadioChange,index:number) {
    if (event.value) {
      Object.keys(this.sowForm.get('sow')['controls'][index]['controls']).forEach((control:any) => {
        this.sowForm.get('sow')['controls'][index]?.get(control).setValidators(Validators.required);
        control === 'projectID' ? this.sowForm.get('sow')['controls'][index]?.get(control).setValidators(Validators.pattern("^[0-9]*$")) : "";
        this.sowForm.get('sow')['controls'][index]?.get(control).updateValueAndValidity();
      });
    }else{
      Object.keys(this.sowForm.get('sow')['controls'][index]['controls']).forEach((control:any) => {
        this.sowForm.get('sow')['controls'][index]?.get(control).clearValidators();
        this.sowForm.get('sow')['controls'][index]?.get(control).updateValueAndValidity();
      });
      this.sowForm.get('sow')['controls'][index]?.get('contractAwarded').setValidators(Validators.required);
      this.sowForm.get('sow')['controls'][index]?.get('contractAwarded').updateValueAndValidity();
    }
  }
  getVendorToe() {
    this.spinner.show();
    this.toeService.getVendorToe(this.opportunityId).subscribe((res: any) => {
      this.spinner.hide();
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: res.opportunity_data.portfolio_details[0].name, url: '/program', queryParams: {portfolio: res?.opportunity_data.portfolio_details[0]?.id} },
        { label: res.opportunity_data.portfolio_details[1].name, url: '/program', queryParams: {subportfolio: res?.opportunity_data.portfolio_details[1]?.id} },
        { label: res.opportunity_data.program?.name, url: '/program', queryParams: {program: res?.opportunity_data.program?.id} }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)
  
      this.breadCrumService.setBreadcrumb(breadCrumbFirst);

      
      this.opportunity_data = res.opportunity_data;
      let sorted = res.records.sort((a: any,b: any) => a.vendor_details.name > b.vendor_details.name ? 1 : -1)

      this.list = sorted;
      this.list = this.list.filter((e:any)=>e.vendor_status?.[0])
      this.ps.setStatusValue(res.status);
      let aFormArray:any = this.sowForm?.get('sow') as FormArray;
      this.list = this.list.map((i: any) => {
        this.statusList?.forEach((element: any) => {
          if (element.action_complete_label == i.status.action_complete_label) {
            i.updatedStatus = element;
            this.spinner.hide();
          }
        });
        aFormArray.push(this.createForm());
        return i;
      })  
      this.sowForm = this.fb.group({
        sow: aFormArray
      })  
      this.ps.getOppData(this.opportunityId).subscribe((res:any)=>{      
        this.opportunityStatus = res?.status;
        // if(res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled'){
        //   this.disableFields = true;
        //   this.sowForm.get("sow").controls.forEach((control:any,index:number) => {
        //     this.sowForm.get("sow").controls[index].disable();
        //   });
        // } 
      })     
        
    })
    this.spinner.hide();
  }
  checkAccordion(item:any){
    if(item.vendor_status?.[0]?.status_details?.slug == 'proposal_sow_signed' || item.vendor_status?.[0]?.status_details?.slug == 'proposal_rejected' || this.opportunityStatus?.slug == 'opportunity_cancelled' ){
      this.disableFields = true;
      this.sowForm.get("sow").controls.forEach((control:any,index:number) => {
        this.sowForm.get("sow").controls[index].disable();
      });
    }else{
      this.disableFields=false;
      this.sowForm.get("sow").controls.forEach((control:any,index:number) => {
        this.sowForm.get("sow").controls[index].enable();
      });
    }
  }
  onChangeTable(page: any) {
    this.page = page;
    this.startVal = (page - 1) * (this.itemsPerPage);
    this.endVal = this.startVal + (this.itemsPerPage);
    this.getVendorToe();
  }

  updateToeApproval() {
    const payload = {
      opportunity: this.opportunityId,
      vendor: this.selectedVendor,
      status: this.selectedStatus
    }
    this.toeService.updateToeApproval(payload).subscribe((res: any) => {
      if (res == true) {
        this.toastrService.success("Updated Succesfully")
        this.getVendorToe();
      }
    }, error => {
      throw error;
    })
  }

  getStatusList() {
    this.spinner.show();
    this.toeService.getStatusList().subscribe((res: any) => {
      this.spinner.hide();
      console.log('status list', res);
      this.statusList = res;
      this.getVendorToe();
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  selectToeStatus(e: any, i: any) {
    console.log('e', e);
    console.log('i', i)
    this.selectedStatus = e.value.slug;
    this.selectedVendor = parseInt(i.vendor);
    this.updateToeApproval();
  }
  getShortName(fullName: any) {
    return fullName.split(' ').map((n: any) => n[0]).join('');
  }
  getName(name: any) {
    if(!name){
return "";
    }
    return name.split(' ').slice(0, 2).map((n: any) => n[0]).join('');
  }
  screenInfo(id: any) {
    this.router.navigate([`toe/${btoa(id)}/delivery-contract`]);
  }
  changeTab(tab:any,vendor:any,vendorIndex:number){
    //this.activeTab[vendorIndex] = tab;
    this.files[vendorIndex]=[];
    if(tab?.index === 1){
      this.getSowContractDetails(vendor,vendorIndex);
    }
  }
  getClickedVendor(vendor:any,vendorIndex:number){
    this.getSowContractDetails(vendor,vendorIndex);
  }
  onSelectFile(evt:any,index:number){
    this.sowForm.get('sow')['controls'][index].get('document').setValue(evt?.target.files[0].name);
    this.selectedFile[index] = evt?.target.files[0];
    this.files[index] = evt?.target.files;
    this.files[index][0]['file']=evt?.target.files[0].name;
    
  }
  saveResponse(index:any){
    if(this.sowForm.get('sow')['controls'][index].invalid){
      this.toastrService.error("Please fill mandatory fields with valid input");
      return;
    }
    this.spinner.show();
    let formData = new FormData();
    if(this.sowForm.get('sow')['controls'][index].get('contractAwarded').value){
      formData.append('file',this.selectedFile[index]);
      formData.append('file_name',this.sowForm.get('sow')['controls'][index].get('document').value);
      formData.append('project_id',this.sowForm.get('sow')['controls'][index].get('projectID').value);
      formData.append('project_name',this.sowForm.get('sow')['controls'][index].get('projectName').value);
      formData.append('project_value',this.sowForm.get('sow')['controls'][index].get('projectValue').value);
      let beginDate:any =this.sowForm.get('sow')['controls'][index].get('beginDate').value;
      formData.append('begin_date',<any>this.datePipe.transform(beginDate,'yyyy-MM-dd'));
      let endDate:any =this.sowForm.get('sow')['controls'][index].get('endDate').value;
      formData.append('expected_end_date', <any>this.datePipe.transform(endDate,'yyyy-MM-dd'));
    }
    let vendorsList = [this.list[index]?.vendor];
    formData.append('vendor',JSON.stringify(vendorsList));
    formData.append('is_awarded',this.sowForm.get('sow')['controls'][index].get('contractAwarded').value);
    this.toeService.createSowContract(this.opportunityId,formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.ps.getOppData(this.opportunityId).subscribe((res:any)=>{
          this.ps.setStatusValue(res?.status);
          if(res?.status.slug == 'opportunity_cancelled'){
            this.router.navigate([`program`]);            
          }
        });
        this.toastrService.success("Saved Successfully");
        this.getVendorToe();
      }
    }, error => {
      this.spinner.hide();
      this.toastrService.error(error.error)
      throw error;
    })
  }
  getSowContractDetails(vendor:any,vendorIndex:number){
    //this.spinner.show();
    this.toeService.getSowContract(this.opportunityId,vendor.vendor).subscribe((res: any) => {
      //this.spinner.hide();
      if(res){
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("contractAwarded").setValue(res?.is_awarded);
        if (res?.is_awarded) {
          Object.keys(this.sowForm.get('sow')['controls'][vendorIndex]['controls']).forEach((control:any) => {
            this.sowForm.get('sow')['controls'][vendorIndex]?.get(control).setValidators(Validators.required);
            this.sowForm.get('sow')['controls'][vendorIndex]?.get(control).updateValueAndValidity();
          });
        }else{
          Object.keys(this.sowForm.get('sow')['controls'][vendorIndex]['controls']).forEach((control:any) => {
            this.sowForm.get('sow')['controls'][vendorIndex]?.get(control).clearValidators();
            this.sowForm.get('sow')['controls'][vendorIndex]?.get(control).updateValueAndValidity();
          });
          this.sowForm.get('sow')['controls'][vendorIndex]?.get('contractAwarded').setValidators(Validators.required);
          this.sowForm.get('sow')['controls'][vendorIndex]?.get('contractAwarded').updateValueAndValidity();
        }
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("projectID").setValue(res?.project_id);
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("projectName").setValue(res?.project_name);
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("projectValue").setValue(res?.project_value);
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("beginDate").setValue(new Date(res?.begin_date));
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("endDate").setValue(new Date(res?.expected_end_date));
        this.files[vendorIndex] =res?.['signed-contract'];
        let filename = res?.['signed-contract']?.[0]?.file;
        this.sowForm.get("sow")['controls'][vendorIndex]?.get("document").setValue(filename);
      }
    }, error => {
      this.toastrService.error('Could not delete custom Measurement');
      throw error;
    })    
  }
  
  deleteFile(data: any,vendorIndex:number) {
    this.files[vendorIndex] =[];
    this.selectedFile[vendorIndex] ={};
    this.sowForm.get('sow')['controls'][vendorIndex].get('document').setValue(null);
  }
  hideAccordion=(index:number)=>{
    this.list[index]['show']=false;
  }

  getTagObject(id: any){
    return {
      entity_id: id,
      entity_type: "opportunity"
    }
  }


}
