<div class="rightpanel">
    <div class="container-fluid centerpanel p-0">
        <div class="custom-height" id="custom-height">
            <div class="row">
                <div class="col-md-12">
                    <div class=" mb-4">
                        <p class="fw-bold fs-24 mb-0">{{staticText?.proposal?.proposal_evaluation.award_deal}}</p>
                        <p class="m-0 mb-1" style="font-size: 13px;">{{opportunity_data?.opportunity_name}} | {{opportunity_data?.opportunity_number}} | {{opportunity_data?.opportunity_type_details?.name}} | {{opportunity_data?.portfolio_details[0].name}} | {{opportunity_data?.portfolio_details[1].name}}
                            | {{opportunity_data?.program?.name}} | {{opportunity_data?.owners[0].owner_details.full_name}} | {{opportunity_data?.single_point_of_contact?.full_name}}
                        </p>
                        <app-tag [dataObj]="getTagObject(opportunity_data?.id)" [tagsIds]="opportunity_data?.tag_ids" *ngIf="opportunity_data"></app-tag> 
                    </div>
                    <div class="accordion scroll" id="accordionExample" [formGroup]="sowForm">
                        <ng-container formArrayName="sow">
                            <div class="d-flex justify-content-between"
                                *ngFor="let i of list | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount } ; let e = index">
                                <div class="col-md-auto accordion-item mb-2 p-0">
                                    <div class="accordion-header" id="headingOne{{e}}"
                                        (click)="i['show']=true;checkAccordion(i)">
                                        <div class="col-md-auto card-col p-0 position-relative w-100 accordion-button" (click)="getClickedVendor(i,e)"
                                            [ngClass]="{'collapsed':!i['show']}" data-bs-toggle="collapse"
                                            [attr.data-bs-target]="'#collapseOne'+e">
                                            <div
                                                class="row grid m-0 card-grid pb-1 justify-content-between cursor-pointer w-100">
                                                <div class="col-md-12">
                                                    <div class="pl-2 d-flex justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <div class="mr-2">
                                                                <span class=" name-1 "
                                                                    [ngStyle]="{'backgroundColor': e|getRandomColor}">{{getName(i?.vendor_details?.name)}}</span>
                                                            </div>
                                                            <div>
                                                                <div class="score-count">
                                                                    <p class="company-name"
                                                                        matTooltip="{{i.vendor_details.name}}"
                                                                        matTooltipClass="example-tooltip">
                                                                        {{i.vendor_details.name}}</p>
                                                                   </div>
                                                                <br>
                                                                <div class="d-flex">
                                                                    <p class="m-0 medels">AS:<span
                                                                        class="m-0 medels">{{i.score.total_avg_actual}}</span>
                                                                </p><span class="px-2 m-0 medels"> | </span>
                                                                <p class="m-0 medels"> WS:<span
                                                                        class="m-0 medels">{{i.score.total_avg_weighted}}</span>
                                                                </p>
                                                                    <!-- <p class="p-0 m-0 team">{{i.vendor_details.team}}
                                                                    </p> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center justify-content-center p-0">
                                                            <p class="mb-0 px-1 white-color w-auto"
                                                                [ngClass]="{ 'red': i.vendor_status?.[0]?.status_details?.label == 'Draft'|| i.vendor_status?.[0]?.status_details?.label == 'Cancelled' ||i.vendor_status?.[0]?.status_details?.label == 'Rejected' , 'empty': i.vendor_status?.[0]?.status_details?.label == '' ,
                                                                'hold': (i.vendor_status?.[0]?.status_details?.label == 'Create' || i.vendor_status?.[0]?.status_details?.label == 'Modified') ,  
                                                                'partially-closed': i.vendor_status?.[0]?.status_details?.label == 'Evaluating' , 
                                                                'initiated' : i.vendor_status?.[0]?.status_details?.label == 'Published' || !i?.vendor_status,
                                                                'green' : i.vendor_status?.[0]?.status_details?.label == 'SoW Signed'}">
                                                                {{i.vendor_status?.[0]?.status_details?.action_complete_label ||
                                                                'Published'}}
                                                            </p>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                                
                                                <!-- <div class="col-md-3 vendor-details p-0">
                                                    <div class="d-flex">
                                                        <div>
                                                            <span class="name"
                                                                [ngStyle]="{'backgroundColor': e|getRandomColor}">
                                                                {{getName(i.vendor_details.primary_contact_details?.full_name)}}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <p class="fw-bold vendor-name"
                                                                matTooltip="{{i.vendor_details.primary_contact_details?.full_name}}"
                                                                matTooltipClass="example-tooltip">
                                                                {{i.vendor_details.primary_contact_details?.full_name}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div id="collapseOne{{e}}" class="accordion-collapse award-tab"
                                        [ngClass]="i['show']?'show':'collapse'" data-bs-parent="#accordionExample">
                                        <div class="accordion-body p-2">

                                            <!-- <mat-tab-group mat-align-tabs="start" id="previewTab"
                                                (selectedTabChange)="changeTab($event,i,e)"> -->
                                                <!-- <mat-tab label="Terms of Engagement">
                                                    <app-toe-response (onClose)="hideAccordion(e)"
                                                        [opportunityId]="opportunityId" [vendorID]="i.vendor"
                                                        [vendorStatus]="i.vendor_status?.[0]?.status_details?.slug"
                                                        [opportunityStatus]="opportunityStatus"></app-toe-response>
                                                </mat-tab> -->
                                                <!-- <mat-tab label="SoW/Contract"> -->
                                                    <div class="tab-content field-adjust mt-4 pl-2 pr-2">
                                                        <form [formGroupName]="e">
                                                            <div class="d-flex flex-column px-2">
                                                                <mat-radio-group class="pb-2 color-black-pearl"
                                                                    formControlName="contractAwarded"
                                                                    (change)="radioChange($event,e)">
                                                                    <mat-radio-button class="me-3 fnt14"
                                                                        [value]="true">Contract
                                                                        Awarded</mat-radio-button>
                                                                    <mat-radio-button class="me-3 fnt14"
                                                                        [value]="false">Contract Not
                                                                        Awarded</mat-radio-button>
                                                                </mat-radio-group>
                                                            </div>
                                                            <ng-container
                                                                *ngIf="sowForm.get('sow')['controls'][e]?.get('contractAwarded').value">
                                                                <mat-form-field class="col-4 px-2" appearance="outline">
                                                                    <mat-label>Project ID</mat-label>
                                                                    <input type="text" matInput status="basic"
                                                                        formControlName="projectID">
                                                                </mat-form-field>
                                                                <mat-form-field class="col-4 px-2" appearance="outline">
                                                                    <mat-label>Project Name</mat-label>
                                                                    <input type="text" matInput status="basic"
                                                                        formControlName="projectName">
                                                                </mat-form-field>
                                                                <mat-form-field class="py-2 col-4"
                                                                        appearance="outline">
                                                                        <mat-icon matPrefix>attach_money</mat-icon>
                                                                        <mat-label>Project Value</mat-label>
                                                                        <input type="number" matInput status="basic"
                                                                            formControlName="projectValue">
                                                                    </mat-form-field>
                                                                <mat-form-field appearance="outline"
                                                                    class="w-100 col-4 px-2">
                                                                    <mat-label>Begin Date</mat-label>
                                                                    <input matInput [matDatepicker]="picker" readonly
                                                                        formControlName="beginDate" fullWidth
                                                                        status="basic" (click)="picker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker></mat-datepicker>
                                                                </mat-form-field>
                                                                <mat-form-field appearance="outline"
                                                                    class="w-100 col-4 px-2">
                                                                    <mat-label>Expected End Date</mat-label>
                                                                    <input matInput [matDatepicker]="picker1" readonly
                                                                        formControlName="endDate" fullWidth
                                                                        status="basic" (click)="picker1.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker1">
                                                                    </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker1></mat-datepicker>
                                                                </mat-form-field>
                                                                <div class="row px-2">
                                                                    <div class="col-6" *ngIf="!disableFields">
                                                                        <div class="p-2 mt-2 upload">
                                                                            <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                                                (click)="fileInput.click()">
                                                                                <span
                                                                                    class="mt-1 ms-2 my-auto secondary">Upload
                                                                                    Signed SoW</span>
                                                                            </div>
                                                                            <input hidden #fileInput
                                                                                [disabled]="disableFields" type="file"
                                                                                id="file"
                                                                                accept=".pdf,.ppt, .docx,.doc,.pptx,.xls,.xlsx"
                                                                                (change)="onSelectFile($event,e)">
                                                                        </div>
                                                                        <p
                                                                            class="fw-light file-status text-dark fs-14 mb-1">
                                                                            {{staticText?.common.doc_ext_maxsize}}</p>
                                                                    </div>
                                                                    <div class="col-md-auto">
                                                                        <div class="d-flex files">
                                                                            <span *ngFor="let data of files[e]"
                                                                                class="file d-flex">
                                                                                <div class="d-flex">
                                                                                    <img src="./assets/images/docs.png"
                                                                                        height="40"
                                                                                        *ngIf="data?.file?.includes('.doc') || data?.file?.includes('.docx') ">
                                                                                    <img src="./assets/images/pdf-icon-new.svg"
                                                                                        height="40"
                                                                                        *ngIf="data?.file?.includes('.pdf') ">
                                                                                    <img src="./assets/images/powerpoint.png"
                                                                                        height="40"
                                                                                        *ngIf="data?.file?.includes('.pptx') || data?.file?.includes('.ppt')">
                                                                                    <p class="w-100 cursor-pointer"
                                                                                        style="font-size: 8pt;">
                                                                                        {{data.file}}</p>
                                                                                    <img src="./assets/images/delete-icon-new.svg"
                                                                                        class="cursor-pointer"
                                                                                        height="40"
                                                                                        (click)="deleteFile(data,e)"
                                                                                        *ngIf="!disableFields">
                                                                                </div>
                                                                            </span>
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </form>
                                                        <div class="d-flex align-items-center justify-content-end mt-2 pt-2 footer-style"
                                                            *ngIf="data?.term_category != ''">
                                                            <button type="button" mat-button class="mx-1">Close</button>
                                                            <button type="button" mat-button mat-flat-button color="primary" class="bg-primary text-white mx-1"
                                                                *ngIf="!disableFields"
                                                                (click)="saveResponse(e)">Save</button>
                                                        </div>
                                                    </div>
                                                <!-- </mat-tab>
                                            </mat-tab-group> -->
                                            <!-- <div class="tabs">
                                                <ul class="tab-group px-3 pt-3">
                                                <li class="tab ml-0" [ngClass]="{'active':activeTab[e]!='sow'}" (click)="changeTab('toe',i,e)">
                                                    Terms of Engagement
                                                </li>
                                                <li class="tab" [ngClass]="{'active':activeTab[e]=='sow'}" (click)="changeTab('sow',i,e)">
                                                    SoW/Contract
                                                </li>
                                                </ul>
                                                <div class="tab-content-group">
                                                    <app-toe-response *ngIf="activeTab[e]!='sow'" (onClose)="hideAccordion(e)" [opportunityId]="opportunityId" [vendorID]="i.vendor"></app-toe-response>                                                
                                                    <div class="tab-content field-adjust" *ngIf="activeTab[e]=='sow'" >  
                                                        <form [formGroupName]="e">
                                                            <div class="d-flex flex-column px-2">
                                                                <mat-radio-group class="pb-2 color-black-pearl" formControlName="contractAwarded" (change)="radioChange($event,e)">
                                                                    <mat-radio-button class="me-3 fnt14" [value]="true">Contract Awarded</mat-radio-button>
                                                                    <mat-radio-button class="me-3 fnt14" [value]="false">Contract Not Awarded</mat-radio-button>
                                                                </mat-radio-group>
                                                            </div>
                                                            <ng-container *ngIf="sowForm.get('sow')['controls'][e]?.get('contractAwarded').value">
                                                                <mat-form-field class="col-6 px-2" appearance="outline">
                                                                    <mat-label>Project ID</mat-label>
                                                                    <input type="text" matInput status="basic"
                                                                        formControlName="projectID">
                                                                </mat-form-field>
                                                                <mat-form-field class="col-6 px-2" appearance="outline">
                                                                    <mat-label>Project Name</mat-label>
                                                                    <input type="text" matInput status="basic"
                                                                        formControlName="projectName">
                                                                </mat-form-field>
                                                                <div class="row px-2">
                                                                    <mat-form-field class="py-2 col-6" appearance="outline">
                                                                        <mat-label>Project Value</mat-label>
                                                                        <input type="number" matInput status="basic"
                                                                            formControlName="projectValue">
                                                                    </mat-form-field>
                                                                </div>
                                                                <mat-form-field appearance="outline" class="w-100 col-6 px-2">
                                                                    <mat-label>Begin Date</mat-label>
                                                                    <input matInput [matDatepicker]="picker"
                                                                        readonly formControlName="beginDate"
                                                                        fullWidth status="basic" (click)="picker.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker></mat-datepicker>
                                                                </mat-form-field>
                                                                <mat-form-field appearance="outline" class="w-100 col-6 px-2">
                                                                    <mat-label>Expected End Date</mat-label>
                                                                    <input matInput [matDatepicker]="picker1"
                                                                        readonly formControlName="endDate"
                                                                        fullWidth status="basic" (click)="picker1.open()">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker1"> </mat-datepicker-toggle>
                                                                    <mat-datepicker #picker1></mat-datepicker>
                                                                </mat-form-field>
                                                                <div class="row px-2">
                                                                    <div class="col-md-4" *ngIf="!disableFields">
                                                                        <div class="p-2 mt-2 upload">
                                                                            <div class="w-100 cursor-pointer d-flex justify-content-center"
                                                                                (click)="fileInput.click()">
                                                                                <span class="mt-1 ms-2 my-auto secondary">Upload Signed SoW</span>
                                                                            </div>
                                                                            <input hidden #fileInput [disabled]="disableFields" type="file" id="file" accept=".pdf,.ppt, .docx,.doc,.pptx,.xls,.xlsx" (change)="onSelectFile($event,e)">
                                                                        </div>
                                                                        <p class="fw-light file-status text-dark fs-14 mb-1"> {{staticText?.common.doc_ext_maxsize}}</p>
                                                                    </div>
                                                                    <div class="col-md-auto">
                                                                        <div class="d-flex files">                                    
                                                                            <span *ngFor="let data of files" class="file d-flex">
                                                                                <div class="d-flex" >
                                                                                    <img src="./assets/images/docs.png" height="40" *ngIf="data.file.includes('.doc') || data.file.includes('.docx') ">                                                                                    
                                                                                    <img src="./assets/images/pdf-icon-new.svg" height="40" *ngIf="data.file.includes('.pdf') ">
                                                                                    <img src="./assets/images/powerpoint.png" height="40" *ngIf="data.file.includes('.pptx') || data.file.includes('.ppt')">
                                                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                                                        {{data.file}}</p>
                                                                                    <img src="./assets/images/delete-icon-new.svg" class="cursor-pointer" height="40"
                                                                                        (click)="deleteFile(data,e)" *ngIf="!disableFields">
                                                                                </div>
                                                                            </span>
                                                                            <br />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </form>
                                                        <div class="d-flex align-items-center justify-content-end mt-2 pt-2 footer-style" *ngIf="data?.term_category != ''">
                                                            <button type="button" class="btn close-btn pr-2">Close</button>
                                                            <button type="button" class="btn add-btn" [disabled]="disableFields" (click)="saveResponse(e)">Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="row" *ngIf="list?.length == 0">
                            <div class="col-md-5 mx-auto">
                                <img src="./assets/images/no-record.png" class="noFound" height="100%" width="100%">
                            </div>
                            <div class="col-md-8 mx-auto">
                                <p class="text-center fw-bold text-muted"> {{staticText.common.no_records_found}}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row" *ngIf="list?.length != 0" style="min-height: 2rem;">
                        <div class="row">
                            <div class=" d-flex justify-content-end align-items-center ">
                                <div classs="d-flex" style="padding: 0.8rem  2.2rem  0rem 0.1rem;">
                                    <pagination-controls nextLabel="" previousLabel=""
                                        (pageChange)="onChangeTable($event)">
                                    </pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>